type MetaTagsConfig = {
  title: string;
  description: string;
  canonicalUrl: string;
  ogImageUrl: string;
  twitterImageUrl: string;
};

export const buildMetaTags = ({ title, description, canonicalUrl, ogImageUrl, twitterImageUrl }: MetaTagsConfig) => {
  return [
    { title },
    { name: "description", content: description },
    // Canonical URL
    {
      tagName: "link",
      rel: "canonical",
      href: canonicalUrl,
    },
    // Open Graph Meta Tags
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:url",
      content: canonicalUrl,
    },
    {
      property: "og:image",
      content: ogImageUrl,
    },
    {
      property: "og:type",
      content: "website",
    },
    // Twitter Meta Tags
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "twitter:image",
      content: twitterImageUrl,
    },
    {
      name: "twitter:site",
      content: canonicalUrl,
    },
    // Additional useful meta tags
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
    {
      name: "robots",
      content: "index, follow",
    },
    {
      name: "language",
      content: "English",
    },
    {
      property: "og:locale",
      content: "en_US",
    },
  ];
};
